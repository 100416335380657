import React from 'react';
import {
  Accordion,
  Column,
  Container,
  Layout,
  LayoutBlock,
  Hero,
  Title,
  Description,
  Row,
  Section,
  LinkTo,
  Actions,
} from '@dragonchain-dev/dragon-kit';
import { Layout as PageLayout, SEO, TypeForm, FeatureRow } from '../../components/_index';

import Eye from '../../assets/images/icons/eye.svg';
import GearForward from '../../assets/images/icons/gear-forward.svg';
import GlobeDiscussion from '../../assets/images/icons/globe-discussion.svg';
import Label from '../../assets/images/icons/label.svg';
import Money from '../../assets/images/icons/money.svg';
import Website from '../../assets/images/icons/website.svg';

const Den = () => {
  return (
    <PageLayout>
      <SEO
        title="Social Media for Business"
        description="The Den platform is the answer to communities suffering from trolling, censorship, and bias. Every action, post, comment and vote are on chain. Den incentivizes the creation and rapid evaluation of quality content within communities."
      />
      <Layout dark background="fire-dark">
        <Hero type="code">
          <div className="caption">Den</div>
          <Title color="white">Social Media for Business</Title>
          <Description color="blue-light">
          The Den platform is the answer to communities suffering from trolling, censorship, and bias. Every action, post, comment and vote are on chain. Den incentivizes the creation and rapid evaluation of quality content within communities.
          </Description>
        </Hero>
      </Layout>

      <LayoutBlock separator>
        <Container>
          <Row gap="small">
            <Column span={6}>
              <Section title="Features" />
            </Column>
          </Row>
          <FeatureRow
            features={[
              {
                Icon: GearForward,
                title: 'Decentralized Governance',
                body: 'Moderation and censorship authority by community owners in a decentralized and autonomous model.',
              },
              {
                Icon: Money,
                title: 'Monetization',
                body:
                  'Control your own Lair ads, no reselling of data, tokenized monetization for the users, content creators, and evaluators.',
              },
              {
                Icon: Label,
                title: 'White Label',
                body:
                  'Create a branded Lair for yourself, a club, or other organization. Contact us for your own multi-community white label Den platform.',
              },
              {
                Icon: GlobeDiscussion,
                title: 'Unbiased Trending Items',
                body: 'Unlike other platforms Den provides an objective data feed based upon a community’s evaluation of content. Discuss freely, without fear of shadowban or centralized bias.',
              },
              {
                Icon: Website,
                title: 'Governance as a Service',
                body:
                  'Community based ownership and responsibilities incentivized with rewards. Reputation and responsibility are automatically measured and used in moderation of the community. ',
              },
            ]}
          />
          {/* <Row gap="large">
            <Column span={6}>
              <Section title="See/Try it in action">Hello world.</Section>
            </Column>
          </Row> */}
          <Row gap="large">
            <Column span={12}>
              <Accordion title="Frequently Asked Questions">
                <Accordion.Item title="Can we see Den live in action?">
                  <LinkTo type="route" blank to="https://den.social">
                    Yes go here!
                  </LinkTo>
                </Accordion.Item>
                <Accordion.Item title="Who decides what goes in my lair? Can the lair community have their content removed by Den?">
                  Nothing comes between you and your community.
                </Accordion.Item>
              </Accordion>
            </Column>
          </Row>
          <Row>
            <Column span={7}>
              <Section title="White label solutions.">
                Run your own white-labeled social media platform on your own infrastructure. Be in control
                over your own business, university, or non-profit community again, whether it’s private or
                public. The monetization of people’s data you care about ends today. Schedule a call with the
                creators to run your own Den.
              </Section>
            </Column>
            <Column right middle span={5}>
            <Actions
            actions={[
              {
                type: 'link',
                name: 'Contact Us',
                color: 'blue',
                hover: 'blue-dark',
                to: '/contact-us',
              },
            ]}
          />
            </Column>
          </Row>
        </Container>
      </LayoutBlock>
    </PageLayout>
  );
};

export default Den;
